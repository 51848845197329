<script setup lang="ts">
import type { Page } from '~/types';

const { path } = useRoute();
const url = useRequestURL();
const { fileUrl } = useFiles();
const { globals } = useAppConfig();

const pageFilter = computed(() => {
  let finalPath;

  if (path === '/') {
    // Match the homepage
    finalPath = '/';
  } else if (path.endsWith('/')) {
    // Remove any other trailing slash
    finalPath = path.slice(0, -1);
  } else {
    // Match any other page
    finalPath = path;
  }

  return { permalink: { _eq: finalPath } };
});

// ─── FIRST CALL: Fetch minimal page data to get the blocks that apply ──────────
// This call returns the page along with a minimal "blocks" field so we know which block collections are used.
const { data: pageBlocks } = await useAsyncData(
  path + '-blocks',
  () => {
    return useDirectus(
      readItems('pages', {
        filter: unref(pageFilter),
        fields: [
          '*',
          {
            seo: ['*'],
            blocks: ['id', 'collection'] // minimal fields: just the id and block type
          },
        ],
        limit: 1,
      })
    );
  },
  {
    transform: (data) => {
      return data[0];
    },
  }
);

// ─── Define a mapping of block collection names to the fields you need ──────────
const blockFieldMapping = {
  block_hero: [
    'id',
    'title',
    'headline',
    'content',
    'hero_type',
    'hero_size',
    'text_position',
    { image: ['id', 'title', 'description'] },
    { foreground_image: ['id', 'title', 'description'] },
    'image_position',
    {
      button_group: [
        '*',
        { buttons: ['*', { page: ['permalink'], post: ['slug'] }] },
      ],
    },
    {
      carousel_items: [
        { directus_files_id: ['id', 'title', 'description'] },
      ],
    },
  ],
  block_faqs: ['id', 'title', 'faqs', 'headline', 'alignment'],
  block_richtext: ['id', 'title', 'headline', 'content', 'alignment'],
  block_counter: [
    '*',
    { counter_rows: ['*'] },
    {
      button_group: [
        '*',
        { buttons: ['*', { page: ['permalink'], post: ['slug'] }] },
      ],
    },
  ],
  block_testimonials: [
    'id',
    'title',
    'headline',
    'layout_type',
    {
      testimonials: [
        {
          testimonials_id: [
            'id',
            'title',
            'subtitle',
            'content',
            'company',
            'company_logo',
            { image: ['id', 'title', 'description'] },
          ],
        },
      ],
    },
  ],
  block_timeline: [
    'id',
    'title',
    'headline',
    { timeline_items: ['id', 'title', 'date', 'description'] },
  ],
  block_quote: ['id', 'title', 'subtitle', 'content'],
  block_cta: [
    'id',
    'title',
    'headline',
    'content',
    {
      button_group: [
        '*',
        { buttons: ['*', { page: ['permalink'], post: ['slug'] }] },
      ],
    },
  ],
  block_form: ['id', 'title', 'headline', { form: ['*'] }],
  block_cardgrid: [
    'id',
    'title',
    'ui',
    {
      cards: [
        'id',
        'title',
        'description',
        'icon',
        'custom_icon',
        'custom_width',
        'custom_height',
        'orientation',
        { image: ['id', 'title', 'description'] },
        {
          button_group: [
            '*',
            { buttons: ['*', { page: ['permalink'], post: ['slug'] }] },
          ],
        },
      ],
    },
  ],
  block_card: [
    'id',
    'title',
    'description',
    'icon',
    'custom_icon',
    'custom_width',
    'custom_height',
    'orientation',
    { image: ['id', 'title', 'description'] },
    {
      button_group: [
        '*',
        { buttons: ['*', { page: ['permalink'], post: ['slug'] }] },
      ],
    },
  ],
  block_logocloud: [
    'id',
    'title',
    'headline',
    {
      logos: [
        'id',
        'url',
				'custom_class',
        { directus_files_id: ['id', 'title', 'description'] },
      ],
    },
  ],
  block_gallery: [
    'id',
    'title',
    'headline',
    'group_by_category',
    {
      gallery_items: [
        {
          directus_files_id: [
            'id',
            'title',
            'description',
            {
              categories: [
                { categories_id: ['title', 'headline', 'color'] },
              ],
            },
          ],
        },
      ],
    },
    'add_button',
    {
      button_group: [
        '*',
        { buttons: ['*', { page: ['permalink'], post: ['slug'] }] },
      ],
    },
  ],
  block_steps: [
    'id',
    'title',
    'headline',
    'show_step_numbers',
    'alternate_image_position',
    {
      steps: [
        'id',
        'title',
        'content',
        { image: ['id', 'title', 'description'] },
        {
          button_group: [
            '*',
            { buttons: ['*', { page: ['permalink'], post: ['slug'] }] },
          ],
        },
      ],
    },
  ],
  block_columns: [
    'id',
    'title',
    'headline',
    {
      rows: [
        'title',
        'headline',
        'content',
        'image_position',
        { image: ['id', 'title', 'description'] },
        {
          button_group: [
            '*',
            { buttons: ['*', { page: ['permalink'], post: ['slug'] }] },
          ],
        },
      ],
    },
  ],
  block_divider: ['id', 'title', 'emphasize', { image: ['id', 'title', 'description'] }],
  block_team: ['*'],
  block_category_gallery: ['*'],
  block_pulsing_grid: ['*'],
  block_html: ['*'],
  block_video: ['*'],
  block_cardgroup: ['*'],
};

// ─── Compute which block collections are applicable for this page ──────────
const applicableBlockTypes = computed(() => {
  // Map over the blocks from pageBlocks and extract the collection names.
  return pageBlocks.value?.blocks?.map((block) => block.collection) ?? [];
});

// ─── Build the dynamic fields structure for the "item" property ──────────
const dynamicBlockItemFields = computed(() => {
  const result = {};
  // Use only unique block types
  const uniqueTypes = [...new Set(applicableBlockTypes.value)];
  uniqueTypes.forEach((type) => {
    if (blockFieldMapping[type]) {
      result[type] = blockFieldMapping[type];
    }
  });
  return result;
});

// ─── SECOND CALL: Fetch full page data using only the applicable block fields ──────────
const { data: page } = await useAsyncData(
  path + '-full',
  () => {
    return useDirectus(
      readItems('pages', {
        filter: unref(pageFilter),
        fields: [
          '*',
          {
            seo: ['*'],
            blocks: [
              'id',
              'collection',
              'hide_block',
              // Use our dynamic structure for the item field so that only the fields
              // for blocks that are actually used are requested.
              { item: dynamicBlockItemFields.value },
            ],
          },
        ],
        limit: 1,
      })
    );
  },
  {
    transform: (data) => data[0],
  }
);


if (useRuntimeConfig().public.directus.rest.logLevel === 'trace') {
  console.info('Page Data(id): ', page?._value?.id);
  console.info('Page Data(title): ', page?._value?.title);
  console.info('Page Data(permalink): ', page?._value?.permalink);
}

// Error Handling
if (!unref(page)) {
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found' });
}

// ─── Compute metadata (SEO, OG, etc.) as before ──────────
const metadata = computed(() => {
  const pageData = unref(page);
  let ogImage;
  if (pageData?.seo?.og_image) {
    ogImage = fileUrl(pageData.seo.og_image) + '?width=1200';
  } else if (pageData?.blocks?.[0]?.item?.image) {
    ogImage = fileUrl(pageData.blocks[0].item.image) + '?width=1200';
  } else if (globals.og_image) {
    ogImage = fileUrl(globals.og_image) + '?width=1200';
  } else {
    ogImage = undefined;
  }
  return {
    title: pageData?.seo?.title ?? pageData?.title ?? undefined,
    description: pageData?.seo?.meta_description ?? pageData?.summary ?? undefined,
    image: ogImage,
    url: pageData?.seo?.canonical_url ?? url?.href,
  };
});

useHead({
  title: () => unref(metadata)?.title,
  link: [
    { rel: 'canonical', href: () => unref(metadata)?.url },
    { rel: 'icon', type: 'image/ico', href: '/favicon.ico' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '192x192', href: '/android-chrome-192x192.png' },
    { rel: 'icon', type: 'image/png', sizes: '512x512', href: '/android-chrome-512x512.png' },
  ],
});

useSeoMeta({
  description: unref(metadata)?.description,
  ogTitle: unref(metadata)?.title,
  ogDescription: unref(metadata)?.description,
  ogImage: unref(metadata)?.image,
  ogUrl: unref(metadata)?.url,
  twitterTitle: unref(metadata)?.title,
  twitterDescription: unref(metadata)?.description,
  twitterImage: unref(metadata)?.image,
  twitterCard: 'summary_large_image',
});

useServerSeoMeta({
  title: () => unref(metadata)?.title,
  description: () => unref(metadata)?.description,
  ogTitle: () => unref(metadata)?.title,
  ogDescription: () => unref(metadata)?.description,
});
</script>
<template>
  <NuxtErrorBoundary>
    <!-- Render the page using the PageBuilder component -->
    <PageBuilder v-if="page" :page="page as Page" />

    <!-- If there is an error, display it using the VAlert component -->
    <template #error="{ error }">
      <BlockContainer>
        <VAlert type="error">{{ error }}</VAlert>
      </BlockContainer>
    </template>
  </NuxtErrorBoundary>
</template>
